html body {
  min-height: 90vh;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  color: rgb(45, 47, 49);
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", serif;
}


.content {
  min-height: 100vh;
}
.footer {
  font-size: 0.7rem;
  flex-shrink: 0;
}

.vr {
  border-right: solid 1px #007bff;
  padding: 0 0.1rem 0 0;
}

.footerContent {
  padding-bottom: 1rem;
  text-align: center;
}

.footerContent a {
  padding-left: 0.1rem;
}