
@media (max-width: 500px) {
  .formVendor {
    padding: 3%;
  }
}
.formVendor {
  margin: 0 auto;
  max-width: 1000px;
  padding: 1rem 3rem 1rem 3rem;
  height: inherit;
  overflow: visible;
}

.sameAsMain { 
  position: absolute;
  bottom: 1rem;
  font-size: 0.7rem;
}

.relative {
  position: relative;
}

.clearForm {
  margin-top: 0.5rem;
  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  min-height: 36px;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)
}

.upload + label {
  font-size: 0.7em;
  padding: 0.4rem;
  background-color: #233656;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  transition: 150ms;
}

.upload:focus + label,
.upload + label:hover {
  background-color: #415B76;
  cursor:pointer;
}

.title {
  display: inline-block;
}
.logo {
  position: absolute;
  right: 10px;
  top: -15px;
  height: 60px;
}

@media (max-width: 700px) {
  .logo {
    float: none;
  }
  .title {
    display: block;
  }
  .formVendor {
    padding: 3%;
  }
}

.mask {
  color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
}

.buttonGroup {
  padding-bottom: 3rem;
  margin-Top: 2rem;
}

.tooltipQuestion {
  color: #545454;
}
