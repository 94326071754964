.purchasingForm {
    margin: 0 auto;
    max-width: 1200px;
    padding: 1rem;
    position: relative;
}

.purchasingTable {
    overflow-x: scroll;
    border: solid black 2px;
}

.editLink {
    color: blue;
}

.textfieldFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.editHeaders:hover {
    cursor: pointer;
}