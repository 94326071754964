
  .instruction,
  .container {
    margin: 20px;
    width: 400px;
    height: 70px;
  }
  
  .my-textarea {
    font-size: 20px;
    padding: 5px;
    border: 2px solid black;
  }

  .commentString span {
    color: #eb9d37 !important;
    font-size: 105% !important;
    font-weight: bolder !important;
  }
  