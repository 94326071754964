/* .icon {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
  } */


/* VARIABLES */

:root {
    --main-color: #ff941a;
    --text-color: #777;
    --text-color-light: rgb(224, 224, 224);
    --border-color: #e4e4e4;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
    --secondary-color: rgb(126, 122, 122);
    --primary-color: #E86613;
}


/* GENERAL */

/* * {
  box-sizing: border-box;
}

body {
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color);
    background: var(--bg-color);
    position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: .25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
} */


/* GRID */

.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-left: 0 !important;
}

.row-middle {
    align-items: center;
}

.col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

.col-start {
    justify-content: flex-start;
    text-align: left;
}

.col-center {
    justify-content: center;
    text-align: center;
    width: 200px !important;
}

.col-end {
    justify-content: flex-end;
    text-align: right;
}

/* Calendar */

.calendar {
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
    border: 1px solid var(--border-color);
}

.calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 105%;
    padding: 1.5em 0 0.5rem 0;
    border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
    cursor: pointer;
    transition: .15s ease-out;
}

.calendar .header .icon:hover {
    transform: scale(1.75);
    transition: .25s ease-out;
    color: var(--main-color);
}

.calendar .header .icon:first-of-type {
    margin-left: 1em;
}

.calendar .header .icon:last-of-type {
    margin-right: 1em;
}

.calendar .days {
    text-transform: uppercase;
    font-weight: 900;
    color: #545454;
    font-size: 80%;
    padding: .2em 0;
    border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
    position: relative;
    height: 5em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.5s ease-out;
}

.calendar .body .cell:hover {
    background: var(--bg-color);
    transition: 0.5s ease-out;
}

.calendar .body .row {
    border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell:last-child {
    border-right: none;
}

.calendar .body .cell .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .75em;
    right: .75em;
    font-weight: 700;
}

.calendar .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
}

.calendar .body .cell .bg {
    color: var(--secondary-color);
    font-size: 2em;
    position: absolute;
    top: 35%;
    right: 15%;
    transition: .25s ease-out;
}

.calendar .body .cell .bg-left {
    color: var(--main-color);
    font-size: 2em;
    position: absolute;
    top: 35%;
    left: 15%;
    transition: .25s ease-out;
}

.calendar .body .cell .sec {
    color: var(--text-color);
    font-size: 2em;
    position: absolute;
    top: 35%;
    left: 10%;
    transition: .25s ease-out;
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg {
    opacity: 0.95;
    transition: .5s ease-in;
}

.calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
}

.calendar .body .selected {
    background-color: rgba(84, 84, 84, .1);
    transition: .5s ease-out;
}

.countToday::before {
     background: var(--primary-color) !important;
 }

.countUpcoming::before {
    border: 1px solid #007bff;
}

.countToday::before, .countUpcoming::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 5px;
}

@media only screen and (max-width: 600px) {
    .countToday ~ span, .countUpcoming ~ span {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    .calendar .body .col  {
        padding: 0;
    }
}

@media only screen and (max-width: 1070px) {
    .countToday::before, .countUpcoming::before {
        display: none;
    }
    .countToday, .countUpcoming {
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 20px;
        position: relative;
        top: 3px;
        padding-left: 4px;
    }
    .countToday{
        padding-left: 5px;
    }
    .countToday {
        background: var(--primary-color) !important;
        color: white !important;
    }

    .countUpcoming {
        border: 1px solid #007bff;
    }

}

@media only screen and (max-width: 1070px) {
    .countToday ~ span, .countUpcoming ~ span {
        display: none;
    }
    .calendar .body .col div{
        left: 40%;
        top: 30%;
    }

    .countToday, .countUpcoming {
        width: 25px;
        height: 25px;
        padding-left: 8px;
        padding-top: 2px;
    }
    .countUpcoming {
        margin-top: 2px;
    }
}

.countsContainer {
    position: absolute;
    top: 35%;
    font-size: 0.85rem;
}

