html body {
  min-height: 90vh;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  color: rgb(45, 47, 49);
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Roboto", serif;
}


.content {
  min-height: 100vh;
}
.footer {
  font-size: 0.7rem;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
}

.vr {
  border-right: solid 1px #007bff;
  padding: 0 0.1rem 0 0;
}

.footerContent {
  padding-bottom: 1rem;
  text-align: center;
}

.footerContent a {
  padding-left: 0.1rem;
}
:root {
  --primary-color: #E86613;
}

button:focus {
  outline: 0 !important;;
}

.App {
  position: relative;
  top: 0rem;
  width: 100%;
}

.appcontainer {
  padding: 4%;
  max-width: 1000px;
  margin: 0 auto;
}

.vl-primary-text-color {
  color: #E86613 !important;
  color: var(--primary-color) !important;
}

.vl-primary-bg-color {
  background-color: #E86613 !important;
  background-color: var(--primary-color) !important;
}

.userHr {
  margin-bottom: 0;
}

.signOut {
  color: black;
  background-color: green;
}

.userInfo {
  padding: 0%;
  width: 300px;
  font-size: 0.8rem;
}

.appMenu {
  padding: 5%;
}

.appMenuList {
  color: black;
}

.accountCircle:hover {
  cursor: pointer;
}

.dropdownBtn {
  background-color: 'none'
}

.userStatus {
  padding: 1rem;
}

.dropdownBtn
.dropdown-item:active {
  background-color:rgba(0,0,0,0.8) !important;
  color: white !important;
}

.dropdownBtn
.dropdown-item:active {
  background-color:rgba(0,0,0,0.8) !important;
  color: white !important;
}

.dropdownBtn
.dropdown-item:hover {
  background-color: rgba(0,0,0,0.9);
}

.ApplicationBar {
  background-color: #656565 !important;
  color: white  !important;
}

.appBarMargin {
  margin-top: 6rem;
}

.primaryVLcolor {
  color: #E86613 !important;
  color: var(--primary-color) !important;  
}

.secondaryVLcolor {
  color: #545454 !important;
}

.primaryVLButton {
  color: white !important;
  background-color: #E86613 !important;
  background-color: var(--primary-color) !important;
}
.primaryVLButton:hover {
  color: white !important;
  background-color: #E86613 !important;
  background-color: var(--primary-color) !important;
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
  cursor: pointer;
}

.primaryTextVLButton {
  color: #E86613 !important;
  color: var(--primary-color) !important;
  border-color: #E86613 !important;
  border-color: var(--primary-color) !important;
  background-color: white !important;
}

.primaryTextVLButton:hover {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
  cursor: pointer;
}


.secondaryVLButton {
  color: white !important;
  background-color: #545454 !important;
}

.hamburger:hover {
  cursor: pointer;
}

.appBarItem {
  color: 'orange';
}

.footerBox {
  display: block;
  padding: 20px;
  height: 60px;
  width: 100%;
}
.footerContainer {
  z-index: 50;
  background-color: #F8F8F8;
  border-top: 1px solid #E7E7E7;
  text-align: center;
  padding: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 40px;
  width: 100%;
  font-size: 0.7rem;
}

.editLink {
  color: rgba(0,0,255, 0.8);
}

.editLink:hover {
  cursor: pointer;
  color: rgba(0,0,255, 1)
}

.hover:hover {
  cursor: pointer;
}

.editHover:hover {
  cursor: pointer;
}

.MuiExpansionPanel-root:before {
  height: 0 !important;
}

.react-select__menu {
  z-index: 1000 !important;
}

.noFocus:focus {
  border: none;
  outline: none;
}

.noOverflowX {
  overflow-x: hidden;
}

.mainTabNav {
    font-size: 1.3rem;
    background-color: #E86613 !important;
    background-color: var(--primary-color) !important;
    color: white;
    border-radius: 5px;
    height: 40px;
    margin: auto;
}

.nav-item:hover {
    cursor: pointer;
    background-color: #E86613 !important;
    background-color: var(--primary-color) !important;
    brightness: 80%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.Home .lander {
  position: relative;
  padding-top: 4rem;
  text-align: center;
  -webkit-align-content:  center;
          align-content:  center;
}


.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.Home .lander p {
  color: #999;
}

.columnBox {
  color: #656565;
  border: 2px solid #656565;
  text-align: center;
  margin: 2%;
  padding: 5%;
  border-radius: 10px;
  max-width: 400px;
  margin: 0 auto;
  min-height: 360px;
}

.homeCard {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 5%;
  color: #656565
}

.homeCard .center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2%;
}

@media all and (min-width: 480px) {
  .Login form {
    color: #333;
    margin: 0 auto;
  }
}

/* .login {
  height: 100vh;
  align-content: center;
  text-align: center;
  margin: auto, 0;
} */

.loginInput {
  height: 50px;
  border-radius: 40%;
  /* width: 300px; */
}

input:focus {
  background-position: 0 0;
}

.loginButton {
  height: 50px;
  width: 100%;
  border-radius: 100px !important;
  margin-top: 8px;
  background-color: #656565 !important;
  color: white !important;
}

/* .center {
  position: absolute;
  width: 300px;
  height: 50px;
  top: 30%;
  left: 50%;
  margin-left: -150px; 
  margin-top: -25px;
}​

.loginTitle {
  border-bottom: solid #333;
  border-width: 1px;
  margin-bottom: 1rem;
}

.logincard {
  padding: 5%;
  border: solid black 1px;
  border-radius: 5px;
  max-width: 400px;
  margin: 0 auto;
} */

.forgot {
  margin-top: 2rem;
  text-align: center;
}

.forgotBox {
    color: #656565;
    border: 2px solid #656565;
    text-align: center;
    margin: 2%;
    padding: 4%;
    border-radius: 10px;
    max-width: 400px;
    margin: 0 auto;
}

.passwordfield {
  border-radius: 40%;
  width: 300px;
}
.NotFound {
  padding-top: 100px;
  text-align: center;
}


@media (max-width: 500px) {
  .formVendor {
    padding: 3%;
  }
}
.formVendor {
  margin: 0 auto;
  max-width: 1000px;
  padding: 1rem 3rem 1rem 3rem;
  height: inherit;
  overflow: visible;
}

.sameAsMain { 
  position: absolute;
  bottom: 1rem;
  font-size: 0.7rem;
}

.relative {
  position: relative;
}

.clearForm {
  margin-top: 0.5rem;
  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  box-sizing: border-box;
  min-height: 36px;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  border-radius: 4px;
  text-transform: uppercase;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)
}

.upload + label {
  font-size: 0.7em;
  padding: 0.4rem;
  background-color: #233656;
  font-weight: 500;
  color: white;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
  border-radius: 10px;
  transition: 150ms;
}

.upload:focus + label,
.upload + label:hover {
  background-color: #415B76;
  cursor:pointer;
}

.title {
  display: inline-block;
}
.logo {
  position: absolute;
  right: 10px;
  top: -15px;
  height: 60px;
}

@media (max-width: 700px) {
  .logo {
    float: none;
  }
  .title {
    display: block;
  }
  .formVendor {
    padding: 3%;
  }
}

.mask {
  color: rgba(0, 0, 0, 0.54);
    padding: 0;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    line-height: 1;
}

.buttonGroup {
  padding-bottom: 3rem;
  margin-Top: 2rem;
}

.tooltipQuestion {
  color: #545454;
}

.vendorConformation {
  padding: 10% 5%;
  margin: 0 auto;
  text-align: center;
  max-width: 1000px;
}


.container {
  padding: 3%;
  position: relative;
}

.cardDetails {
  width: 100%;
  padding: 4%;
  border-radius: 5px;
  position: relative;
}

.tableRow:nth-child(2n) {
  background-color: rgb(244, 244, 244);
}

.centerGrid {
  margin: 0 auto;
  max-width: 600px;
}

.buttons {
  margin-Top: 3%;
}

.downloadable {
  color: rgb(0, 104, 178);
}

.downloadable:hover {
  cursor: pointer;
  text-decoration: underline;
}


  .instruction,
  .container {
    margin: 20px;
    width: 400px;
    height: 70px;
  }
  
  .my-textarea {
    font-size: 20px;
    padding: 5px;
    border: 2px solid black;
  }

  .commentString span {
    color: #eb9d37 !important;
    font-size: 105% !important;
    font-weight: bolder !important;
  }
  
.tableContainer {
    margin: 5%;
}

.generalSetupTitle {
    padding: 1rem;
    text-align: center;
}

.makePanel {
    max-width: 1000px;
    margin: 0 auto;
    border: none;
    padding: 0;
}

.generalAdd {
    background-color: rgb(232,102,19);
    margin-top: 1rem;
}

.generalAdd:hover {
    text-decoration: underline;
    cursor: pointer;
}

.innerPanel {
    margin: 0 auto;
    padding: 3%;

}

.editLink {
    color: rgb(0, 104, 178);
    margin: 0 auto;
}

.editLink:hover {
    cursor: pointer;
    text-decoration: underline;
}

/* .approvalSettings {
    overflow: scroll;
} */

.settingsContainer {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    padding: 3%;
}
.rejectContainer {
    margin: 0 auto;
    padding: 2rem;
    max-width: 1300px;
}

@media (max-width: 800px){
    .rejectContainer {
        padding: 0.5rem 0.1rem;
    }

}

.dataTables_filter {
    float: right
}

.dataTables_info {
    font-size: 0.7rem;
}

@media (max-width: 767px) {
    .dataTables_filter {
        float: none
    }
}

.table td {
    vertical-align: middle;
}

.addInput {
    width: 100%;
    border-radius: 0;
}

.userTitle {
    margin-bottom: 3%;
}

.page-item.active .page-link {
    background-color: rgb(232,102,19);
    border-color: rgb(232,102,19);
}
.purchasingForm {
    margin: 0 auto;
    max-width: 1200px;
    padding: 1rem;
    position: relative;
}

.purchasingTable {
    overflow-x: scroll;
    border: solid black 2px;
}

.editLink {
    color: blue;
}

.textfieldFooter {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.editHeaders:hover {
    cursor: pointer;
}
.tableContainer {
  position: relative;
  -webkit-align-content: center;
          align-content: center;
  padding: 3%;
}

.tableCard {
  position: relative;
  margin: 0 auto;
  max-width: 1400px;
}

.editLink {
  color: rgb(0, 104, 178);
  margin: 0 auto;
}

.editLink:hover {
  cursor: pointer;
  text-decoration: underline;
}

.paymentForm {
    margin: 0 auto;
    padding: 2rem;
    max-width: 1300px;
    /* overflow: scroll; */
}

@media (max-width: 700px){
    .paymentForm {
        padding: 0.5rem;
    }

}

.editLink {
    color: blue;
}

.editLink:hover {
    cursor: pointer;
    text-decoration: underline;
}
.vendorHomeContainer {
    margin: 0 auto;
    max-width: 900px;
}

.nonActive {
    border: solid #E86613 2px;
    padding: 1rem;
    border-radius: 10px;
    background-color: #FF9F61;
    margin: 1rem 1rem 0 1rem;
}

.vendorLandingContainer {
    margin: 0 auto;
    padding: 1rem;
}

@media (max-width: 700px) {
    .vendorLandingContainer {
      padding: 0.1rem;
    }
  }

.unpaid {
    margin-left: 0.6rem;
    font-size: 1.2rem; 
}

.vendorSettings {
    margin-left: 0.6rem;
    font-size: 1.2rem; 
}

.vendorEdit {
    margin-left: 0.3rem;
    font-size: 0.6rem; 
    color: rgb(0, 104, 178);
}

.vendorEdit:hover {
    cursor: pointer;
}

.welcomeMessage {
    font-size: 1.3rem;
    margin-bottom: 1rem;
}
/* .icon {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
  } */


/* VARIABLES */

:root {
    --main-color: #ff941a;
    --text-color: #777;
    --text-color-light: rgb(224, 224, 224);
    --border-color: #e4e4e4;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
    --secondary-color: rgb(126, 122, 122);
    --primary-color: #E86613;
}


/* GENERAL */

/* * {
  box-sizing: border-box;
}

body {
    font-family: 'Open Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
    font-size: 1em;
    font-weight: 300;
    line-height: 1.5;
    color: var(--text-color);
    background: var(--bg-color);
    position: relative;
}

header {
  display: block;
  width: 100%;
  padding: 1.75em 0;
  border-bottom: 1px solid var(--border-color);
  background: var(--neutral-color);
}

header #logo {
  font-size: 175%;
  text-align: center;
  color: var(--main-color);
  line-height: 1;
}

header #logo .icon {
  padding-right: .25em;
}

main {
  display: block;
  margin: 0 auto;
  margin-top: 5em;
  max-width: 50em;
} */


/* GRID */

.row {
    margin: 0;
    padding: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 100%;
    margin-left: 0 !important;
}

.row-middle {
    -webkit-align-items: center;
            align-items: center;
}

.col {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-basis: 0;
            flex-basis: 0;
    max-width: 100%;
}

.col-start {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    text-align: left;
}

.col-center {
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    width: 200px !important;
}

.col-end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    text-align: right;
}

/* Calendar */

.calendar {
    display: block;
    position: relative;
    width: 100%;
    background: #fff;
    background: var(--neutral-color);
    border: 1px solid #e4e4e4;
    border: 1px solid var(--border-color);
}

.calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 105%;
    padding: 1.5em 0 0.5rem 0;
    border-bottom: 1px solid #e4e4e4;
    border-bottom: 1px solid var(--border-color);
}

.calendar .header .icon {
    cursor: pointer;
    transition: .15s ease-out;
}

.calendar .header .icon:hover {
    -webkit-transform: scale(1.75);
            transform: scale(1.75);
    transition: .25s ease-out;
    color: #ff941a;
    color: var(--main-color);
}

.calendar .header .icon:first-of-type {
    margin-left: 1em;
}

.calendar .header .icon:last-of-type {
    margin-right: 1em;
}

.calendar .days {
    text-transform: uppercase;
    font-weight: 900;
    color: #545454;
    font-size: 80%;
    padding: .2em 0;
    border-bottom: 1px solid #e4e4e4;
    border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell {
    position: relative;
    height: 5em;
    border-right: 1px solid #e4e4e4;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: #fff;
    background: var(--neutral-color);
    transition: 0.5s ease-out;
}

.calendar .body .cell:hover {
    background: #f9f9f9;
    background: var(--bg-color);
    transition: 0.5s ease-out;
}

.calendar .body .row {
    border-bottom: 1px solid #e4e4e4;
    border-bottom: 1px solid var(--border-color);
}

.calendar .body .cell:last-child {
    border-right: none;
}

.calendar .body .cell .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: .75em;
    right: .75em;
    font-weight: 700;
}

.calendar .body .disabled {
    color: rgb(224, 224, 224);
    color: var(--text-color-light);
    pointer-events: none;
}

.calendar .body .cell .bg {
    color: rgb(126, 122, 122);
    color: var(--secondary-color);
    font-size: 2em;
    position: absolute;
    top: 35%;
    right: 15%;
    transition: .25s ease-out;
}

.calendar .body .cell .bg-left {
    color: #ff941a;
    color: var(--main-color);
    font-size: 2em;
    position: absolute;
    top: 35%;
    left: 15%;
    transition: .25s ease-out;
}

.calendar .body .cell .sec {
    color: #777;
    color: var(--text-color);
    font-size: 2em;
    position: absolute;
    top: 35%;
    left: 10%;
    transition: .25s ease-out;
}

.calendar .body .cell:hover .bg, .calendar .body .selected .bg {
    opacity: 0.95;
    transition: .5s ease-in;
}

.calendar .body .col {
    -webkit-flex-grow: 0;
            flex-grow: 0;
    -webkit-flex-basis: calc(100% / 7);
            flex-basis: calc(100% / 7);
    width: calc(100% / 7);
}

.calendar .body .selected {
    background-color: rgba(84, 84, 84, .1);
    transition: .5s ease-out;
}

.countToday::before {
     background: #E86613 !important;
     background: var(--primary-color) !important;
 }

.countUpcoming::before {
    border: 1px solid #007bff;
}

.countToday::before, .countUpcoming::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 5px;
}

@media only screen and (max-width: 600px) {
    .countToday ~ span, .countUpcoming ~ span {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    .calendar .body .col  {
        padding: 0;
    }
}

@media only screen and (max-width: 1070px) {
    .countToday::before, .countUpcoming::before {
        display: none;
    }
    .countToday, .countUpcoming {
        display: inline-block;
        width: 18px;
        height: 18px;
        border-radius: 20px;
        position: relative;
        top: 3px;
        padding-left: 4px;
    }
    .countToday{
        padding-left: 5px;
    }
    .countToday {
        background: #E86613 !important;
        background: var(--primary-color) !important;
        color: white !important;
    }

    .countUpcoming {
        border: 1px solid #007bff;
    }

}

@media only screen and (max-width: 1070px) {
    .countToday ~ span, .countUpcoming ~ span {
        display: none;
    }
    .calendar .body .col div{
        left: 40%;
        top: 30%;
    }

    .countToday, .countUpcoming {
        width: 25px;
        height: 25px;
        padding-left: 8px;
        padding-top: 2px;
    }
    .countUpcoming {
        margin-top: 2px;
    }
}

.countsContainer {
    position: absolute;
    top: 35%;
    font-size: 0.85rem;
}




.mainTabActive {
    margin-top: -5px;
    color: #E86613;
    height: 45px;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: solid 1px grey;
    border-bottom-color: white;
}

.mainNavTab {
    color: white !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: none !important;
}

.top-list-item {
    font-weight: normal;
    line-height: 25px;
    display: block;
    float: left;
    padding: 0 7px;
    height: 25px;
    background: #e5e5e5;
    color: #888;
}

.top-list-item:hover {
    background: #d6d6d6;
}


.topNavLink:hover {
    cursor: pointer;
}

.first-list-item {
 border-bottom-left-radius: 5px ;
}

.last-list-item {
    border-bottom-right-radius: 5px;
}
