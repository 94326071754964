.tableContainer {
    margin: 5%;
}

.generalSetupTitle {
    padding: 1rem;
    text-align: center;
}

.makePanel {
    max-width: 1000px;
    margin: 0 auto;
    border: none;
    padding: 0;
}

.generalAdd {
    background-color: rgb(232,102,19);
    margin-top: 1rem;
}

.generalAdd:hover {
    text-decoration: underline;
    cursor: pointer;
}

.innerPanel {
    margin: 0 auto;
    padding: 3%;

}

.editLink {
    color: rgb(0, 104, 178);
    margin: 0 auto;
}

.editLink:hover {
    cursor: pointer;
    text-decoration: underline;
}

/* .approvalSettings {
    overflow: scroll;
} */

.settingsContainer {
    max-width: 800px;
    margin: 0 auto;
    position: relative;
    padding: 3%;
}
.rejectContainer {
    margin: 0 auto;
    padding: 2rem;
    max-width: 1300px;
}

@media (max-width: 800px){
    .rejectContainer {
        padding: 0.5rem 0.1rem;
    }

}

.dataTables_filter {
    float: right
}

.dataTables_info {
    font-size: 0.7rem;
}

@media (max-width: 767px) {
    .dataTables_filter {
        float: none
    }
}

.table td {
    vertical-align: middle;
}

.addInput {
    width: 100%;
    border-radius: 0;
}

.userTitle {
    margin-bottom: 3%;
}

.page-item.active .page-link {
    background-color: rgb(232,102,19);
    border-color: rgb(232,102,19);
}