

.mainTabActive {
    margin-top: -5px;
    color: #E86613;
    height: 45px;
    background-color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: solid 1px grey;
    border-bottom-color: white;
}

.mainNavTab {
    color: white !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    text-decoration: none !important;
}

.top-list-item {
    font-weight: normal;
    line-height: 25px;
    display: block;
    float: left;
    padding: 0 7px;
    height: 25px;
    background: #e5e5e5;
    color: #888;
}

.top-list-item:hover {
    background: #d6d6d6;
}


.topNavLink:hover {
    cursor: pointer;
}

.first-list-item {
 border-bottom-left-radius: 5px ;
}

.last-list-item {
    border-bottom-right-radius: 5px;
}