.tableContainer {
  position: relative;
  align-content: center;
  padding: 3%;
}

.tableCard {
  position: relative;
  margin: 0 auto;
  max-width: 1400px;
}

.editLink {
  color: rgb(0, 104, 178);
  margin: 0 auto;
}

.editLink:hover {
  cursor: pointer;
  text-decoration: underline;
}
