:root {
  --primary-color: #E86613;
}

button:focus {
  outline: 0 !important;;
}

.App {
  position: relative;
  top: 0rem;
  width: 100%;
}

.appcontainer {
  padding: 4%;
  max-width: 1000px;
  margin: 0 auto;
}

.vl-primary-text-color {
  color: var(--primary-color) !important;
}

.vl-primary-bg-color {
  background-color: var(--primary-color) !important;
}

.userHr {
  margin-bottom: 0;
}

.signOut {
  color: black;
  background-color: green;
}

.userInfo {
  padding: 0%;
  width: 300px;
  font-size: 0.8rem;
}

.appMenu {
  padding: 5%;
}

.appMenuList {
  color: black;
}

.accountCircle:hover {
  cursor: pointer;
}

.dropdownBtn {
  background-color: 'none'
}

.userStatus {
  padding: 1rem;
}

.dropdownBtn
.dropdown-item:active {
  background-color:rgba(0,0,0,0.8) !important;
  color: white !important;
}

.dropdownBtn
.dropdown-item:active {
  background-color:rgba(0,0,0,0.8) !important;
  color: white !important;
}

.dropdownBtn
.dropdown-item:hover {
  background-color: rgba(0,0,0,0.9);
}

.ApplicationBar {
  background-color: #656565 !important;
  color: white  !important;
}

.appBarMargin {
  margin-top: 6rem;
}

.primaryVLcolor {
  color: var(--primary-color) !important;  
}

.secondaryVLcolor {
  color: #545454 !important;
}

.primaryVLButton {
  color: white !important;
  background-color: var(--primary-color) !important;
}
.primaryVLButton:hover {
  color: white !important;
  background-color: var(--primary-color) !important;
  filter: brightness(90%);
  cursor: pointer;
}

.primaryTextVLButton {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
  background-color: white !important;
}

.primaryTextVLButton:hover {
  filter: brightness(90%);
  cursor: pointer;
}


.secondaryVLButton {
  color: white !important;
  background-color: #545454 !important;
}

.hamburger:hover {
  cursor: pointer;
}

.appBarItem {
  color: 'orange';
}

.footerBox {
  display: block;
  padding: 20px;
  height: 60px;
  width: 100%;
}
.footerContainer {
  z-index: 50;
  background-color: #F8F8F8;
  border-top: 1px solid #E7E7E7;
  text-align: center;
  padding: 10px;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 40px;
  width: 100%;
  font-size: 0.7rem;
}

.editLink {
  color: rgba(0,0,255, 0.8);
}

.editLink:hover {
  cursor: pointer;
  color: rgba(0,0,255, 1)
}

.hover:hover {
  cursor: pointer;
}

.editHover:hover {
  cursor: pointer;
}

.MuiExpansionPanel-root:before {
  height: 0 !important;
}

.react-select__menu {
  z-index: 1000 !important;
}

.noFocus:focus {
  border: none;
  outline: none;
}

.noOverflowX {
  overflow-x: hidden;
}

.mainTabNav {
    font-size: 1.3rem;
    background-color: var(--primary-color) !important;
    color: white;
    border-radius: 5px;
    height: 40px;
    margin: auto;
}

.nav-item:hover {
    cursor: pointer;
    background-color: var(--primary-color) !important;
    brightness: 80%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}