.vendorHomeContainer {
    margin: 0 auto;
    max-width: 900px;
}

.nonActive {
    border: solid #E86613 2px;
    padding: 1rem;
    border-radius: 10px;
    background-color: #FF9F61;
    margin: 1rem 1rem 0 1rem;
}

.vendorLandingContainer {
    margin: 0 auto;
    padding: 1rem;
}

@media (max-width: 700px) {
    .vendorLandingContainer {
      padding: 0.1rem;
    }
  }

.unpaid {
    margin-left: 0.6rem;
    font-size: 1.2rem; 
}

.vendorSettings {
    margin-left: 0.6rem;
    font-size: 1.2rem; 
}

.vendorEdit {
    margin-left: 0.3rem;
    font-size: 0.6rem; 
    color: rgb(0, 104, 178);
}

.vendorEdit:hover {
    cursor: pointer;
}

.welcomeMessage {
    font-size: 1.3rem;
    margin-bottom: 1rem;
}