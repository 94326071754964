
.container {
  padding: 3%;
  position: relative;
}

.cardDetails {
  width: 100%;
  padding: 4%;
  border-radius: 5px;
  position: relative;
}

.tableRow:nth-child(2n) {
  background-color: rgb(244, 244, 244);
}

.centerGrid {
  margin: 0 auto;
  max-width: 600px;
}

.buttons {
  margin-Top: 3%;
}

.downloadable {
  color: rgb(0, 104, 178);
}

.downloadable:hover {
  cursor: pointer;
  text-decoration: underline;
}
