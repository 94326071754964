.paymentForm {
    margin: 0 auto;
    padding: 2rem;
    max-width: 1300px;
    /* overflow: scroll; */
}

@media (max-width: 700px){
    .paymentForm {
        padding: 0.5rem;
    }

}

.editLink {
    color: blue;
}

.editLink:hover {
    cursor: pointer;
    text-decoration: underline;
}